import { Alert, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';
import { ContainerWithForm } from '../../components/Container';
import {
  CLIENT_MEDICARE_CARD_DETAILS,
  CONSENTS,
  HEALTH_SUMMARY_CONTINUE,
} from '../../constants/routes';
import FooterNavigator from '../../components/FooterNavigator';
import AgreeCheck from '../../components/AgreeCheck';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { addConsentsData, selectBookingData } from '../../redux/slices/booking';
import { CONSENTS_MAPPING } from '../../constants/consents';
import { checkIfSelectedSpecialistIsNurse } from '../../utils/specialist';

const FeesAndCancellationPolicy = () => {
  const dispatch = useAppDispatch();
  const [isAgreedTheFees, setIsAgreedTheFees] = useState(true);
  const [isAgreedTheCancelPolicy, setIsAgreedTheCancelPolicy] = useState(true);

  const bookingData = useSelector(selectBookingData);
  const backUrl = useMemo(() => {
    const specialistId = bookingData?.appointment?.specialistId;
    const isSelectedSpecialistNurse = checkIfSelectedSpecialistIsNurse(specialistId);
    return isSelectedSpecialistNurse ? CLIENT_MEDICARE_CARD_DETAILS : HEALTH_SUMMARY_CONTINUE;
  }, [bookingData?.appointment?.specialistId]);

  const submit = useCallback(() => {
    if (isAgreedTheFees && isAgreedTheCancelPolicy) {
      dispatch(addConsentsData({
        isAgreedTheFees: { ...CONSENTS_MAPPING.isAgreedTheFees, agreed: true },
        isAgreedTheCancelPolicy: { ...CONSENTS_MAPPING.isAgreedTheCancelPolicy, agreed: true },
      }));
      return true;
    }
    return false;
  }, [isAgreedTheFees, isAgreedTheCancelPolicy, dispatch]);

  return (
    <ContainerWithForm>
      <Typography.Title
        level={2}
        style={{ marginBottom: '50px', textAlign: 'center' }}
      >
        Fees and Cancellation Policy
      </Typography.Title>

      <AgreeCheck
        text={(
          <>
            <Text>I have had a chance to review the Heyday Clinic fees available on the </Text>
            <a
              href="https://www.heydaymedical.com.au/pricing"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              website
            </a>
            <Text> and agree to pay the full fee owing on the day of my consultation.</Text>
          </>
          )}
        isAgreed={isAgreedTheFees}
        setIsAgreed={setIsAgreedTheFees}
      />

      <AgreeCheck
        text={['Our doctors have reserved their time for you. If you must cancel your consultation, we respectfully request 48 hours notice so we have time to fill your spot.', 'Missed consultations, or consultations cancelled without valid medical reason within 48 hours of the consultation, will incur a fee of $150.']}
        isAgreed={isAgreedTheCancelPolicy}
        setIsAgreed={setIsAgreedTheCancelPolicy}
      />

      {(!isAgreedTheCancelPolicy || !isAgreedTheFees) && (
        <Alert
          message={`Please agree to the ${!isAgreedTheCancelPolicy ? 'cancellation policy' : 'fees policy'} before continue.`}
          type="error"
        />
      )}

      <FooterNavigator submit={submit} error="" next={CONSENTS} back={backUrl} containerStyle={{ marginTop: '30px' }} />
    </ContainerWithForm>
  );
};

export default FeesAndCancellationPolicy;
