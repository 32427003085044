import React, {
  FC, useCallback, useMemo,
} from 'react';
import { Calendar } from 'antd';
import moment from 'moment';

import {
  DAY, DATE_WITH_DASH, MONTH,
} from '../../constants/time-formats';
import AvailableTime from '../../model/AvailableTime';
import { BookingTime } from '../../model';
import {
  dateStyleMapping,
  TimeBlock,
} from '../AvailableDateAndTimeBlock';

type Props = {
  handleClickCalendar?: () => void;
  availableTimeList: AvailableTime[];
  bookingTime: BookingTime;
  // eslint-disable-next-line no-unused-vars
  updateBookingTime: (data: BookingTime) => void;
};

const BookingCalendar: FC<Props> = ({
  availableTimeList, bookingTime, updateBookingTime,
  handleClickCalendar,
}) => {
  const availableDates = useMemo(() => (availableTimeList ? availableTimeList.map((availableDate) => availableDate.date) : []), [availableTimeList]);
  let isOnPanelChange = false;

  const customRenderDate = useCallback(
    (current) => {
      const currentDate = moment(current).format(DATE_WITH_DASH);
      const currentDay = current.format(DAY);
      let dateType = '';
      if (availableTimeList && availableTimeList?.length > 0 && availableDates.includes(currentDate)) {
        if (bookingTime.date && bookingTime.date === currentDate) {
          dateType = 'selectedTime';
        } else {
          dateType = 'availableTime';
        }
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <TimeBlock {...dateStyleMapping[dateType]}>{currentDay}</TimeBlock>;
    },
    [bookingTime.date, availableDates, availableTimeList],
  );

  return (
    <Calendar
      onSelect={(date) => {
        if (!isOnPanelChange && handleClickCalendar) {
          handleClickCalendar();
        }
        const selectedDate = date.format(DATE_WITH_DASH);
        if (availableDates.includes(selectedDate)) {
          updateBookingTime({
            date: selectedDate,
            month: date.format(MONTH),
          });
        }
      }}
      onPanelChange={(date) => {
        isOnPanelChange = true;
        const selectedDate = date.format(MONTH);
        updateBookingTime({
          month: selectedDate,
        });
      }}
      fullscreen={false}
      value={moment(bookingTime.month, MONTH)}
      dateFullCellRender={customRenderDate}
    />
  );
};

export default BookingCalendar;
