import { Form, Input, Radio } from 'antd';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import { isNil } from 'lodash';

import { FormContainer } from '../../../components/Container';
import FooterNavigator from '../../../components/FooterNavigator';
import { CLIENT_CREDIT_CARD_DETAILS, HEALTH_SUMMARY_CONTINUE } from '../../../constants/routes';
import {
  addAttachmentsData,
  addBookingQuestionAndAnswersData,
  selectBookingAttachmentsData,
  selectBookingQuestionAndAnswersData,
} from '../../../redux/slices/booking';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import UploadForm from '../../../components/UploadForm';
import { HealthSummaryQuestionMapping } from '../../../model';

const HealthSummaryForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const questionAndAnswersData = useSelector(selectBookingQuestionAndAnswersData);
  const { healthSummary, medicalSupporting } = useSelector(selectBookingAttachmentsData);

  const handleUploadHealthSummary = useCallback((attachment) => dispatch(addAttachmentsData({ healthSummary: attachment })), [dispatch]);
  const handleUploadMedicalSupporting = useCallback((attachment) => dispatch(addAttachmentsData({ medicalSupporting: attachment })), [dispatch]);

  const submit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      dispatch(addBookingQuestionAndAnswersData(values));
      return true;
    } catch (errorInfo) {
      return false;
    }
  }, [form, dispatch]);

  useEffect(() => {
    if (!isNil(questionAndAnswersData)) {
      form.setFieldsValue(questionAndAnswersData);
    }
  }, [questionAndAnswersData, form]);

  return (
    <>
      <FormContainer layout="vertical" form={form}>
        <Form.Item
          label={HealthSummaryQuestionMapping.gpOrSpecialistName}
          required
          name="gpOrSpecialistName"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="ifSendToGpOrSpecialist"
          label={HealthSummaryQuestionMapping.ifSendToGpOrSpecialist}
          required
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <Radio.Group>
            <Radio value="yes">YES</Radio>
            <Radio value="no">NO</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="If you have a referral or health summary from your GP please upload it here:"
        >
          <UploadForm uploadInfo={healthSummary} setUploadInfo={handleUploadHealthSummary} />
        </Form.Item>

        <Form.Item
          label="If you have any medical supporting documents ( i.e. pathology, imaging, reports etc) please upload here:"
        >
          <UploadForm uploadInfo={medicalSupporting} setUploadInfo={handleUploadMedicalSupporting} />
        </Form.Item>

        <FooterNavigator submit={submit} error="" next={HEALTH_SUMMARY_CONTINUE} back={CLIENT_CREDIT_CARD_DETAILS} containerStyle={{ marginTop: '50px' }} />
      </FormContainer>
    </>
  );
};

export default HealthSummaryForm;
