import styled from 'styled-components';
import { palettes } from '../../styles/color';

type DateStyle = { background?: string, color?: string };

export const dateStyleMapping: {[key: string]: DateStyle} = {
  availableTime: {
    background: palettes[3],
    color: 'white',
  },
  selectedTime: {
    background: palettes[5],
    color: 'white',
  },
};

export const AvailableDateAndTimeBlock = styled.div`
  background: ${palettes[3]};
  color: white;
`;

export const TimeBlock = styled.div<DateStyle>`
  ${({ background }) => (`background: ${background};` || '')}
  ${({ color }) => (`color: ${color};` || '')}
`;
