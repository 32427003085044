import React, {
  FC, useCallback, CSSProperties, useState,
} from 'react';
import { Alert, Button, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

type Props = {
  back: string;
  next: string;
  containerStyle?: CSSProperties;
  submit?: () => Promise<boolean> | boolean;
  error?: string;
  loading?: boolean;
};

const FooterNavigator: FC<Props> = ({
  back, next, containerStyle = {}, submit, error = 'Something went wrong', loading,
}) => {
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const handleClickNext = useCallback(async () => {
    if (submit) {
      const isValid = await submit();

      if (isValid) {
        setHasError(false);
        navigate(next);
        return;
      }

      setHasError(true);
    } else {
      navigate(next);
    }
  }, [next, navigate, submit]);

  return (
    <Space direction="vertical" size={20} style={containerStyle}>
      {hasError && error && (
      <Alert
        message={error}
        type="error"
      />
      )}
      <Space size="large">
        <Link to={back}>
          <LeftOutlined />
          Back
        </Link>

        <Button loading={loading || false} shape="round" onClick={handleClickNext} type="primary" htmlType="submit">
          Next
        </Button>
      </Space>
    </Space>
  );
};

FooterNavigator.defaultProps = {
  containerStyle: {},
};

export default FooterNavigator;
