import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Specialists } from '../model';

export const specialistsApi = createApi({
  reducerPath: 'specialistsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/specialists`,
    prepareHeaders: (headers) => {
      headers.set('X-API-KEY', process.env.REACT_APP_API_KEY as string);
      headers.set('Site-Id', process.env.REACT_APP_CURRENT_SITE_ID as string);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSpecialists: builder.query<Specialists, void>({
      query: () => '/',
    }),
  }),
});

export const { useGetSpecialistsQuery } = specialistsApi;
