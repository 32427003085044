import request, { simpleRequest } from '../infra/request';
import { RequestStatus } from '../model';

const { SUCCESS, FAILURE } = RequestStatus;

export type TokenCustomer = {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  redirectUrl: string;
}

export const createTokenCustomer = async (customer: TokenCustomer) => {
  try {
    const result = await request.post('/payment/customer', customer, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY || '',
      },
    });
    const { status, data } = result;

    if (status === 201) {
      return {
        status: SUCCESS,
        data,
      };
    }

    return {
      status: FAILURE,
      error: 'Can not initial the payment',
    };
  } catch (e) {
    return {
      status: FAILURE,
      error: `Can not initial the payment, error: ${e}`,
    };
  }
};

export const createTokenCustomerOnEway = async (url: string, cardInfo: Record<string, string>) => {
  try {
    await simpleRequest.post(url, new URLSearchParams(cardInfo).toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
    // eslint-disable-next-line no-empty
  } catch {}
};

export const fetchTokenCustomerCreationResult = async (accessCode: string) => {
  try {
    const response = await request.get('/payment/customer/creation-result', {
      params: {
        accessCode,
      },
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY || '',
      },
    });
    const { status, data } = response;
    if (status === 200) {
      return {
        status: SUCCESS,
        data,
      };
    }

    return {
      status: FAILURE,
      error: 'Can not initial the payment',
    };
  } catch (e) {
    return {
      status: FAILURE,
      error: `Failed to create token payment, error: ${e}`,
    };
  }
};
