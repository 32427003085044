import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import BookingHome from '../pages/BookingHome';
import Specialists from '../pages/Specialists';
import NotFound from './NotFound';
import {
  ACKNOWLEDGMENTS,
  CLIENT_DETAILS,
  CLIENT_MEDICARE_CARD_DETAILS, CONSENTS, FEES_AND_CANCELLATION_POLICY, FINISH,
  HEALTH_SUMMARY,
  HEALTH_SUMMARY_CONTINUE, ROOT_ROUTE,
  DOCTORS_ROUTE, THANKS, NURSES_ROUTE, CLIENT_CREDIT_CARD_DETAILS,
} from '../constants/routes';
import ClientDetails from '../pages/ClientDetails';
import MedicareDetails from '../pages/MedicareDetails';
import HealthSummary from '../pages/HealthSummary';
import HealthSummaryContinue from '../pages/HealthSummaryContinue';
import FeesAndCancellationPolicy from '../pages/FeesAndCancellationPolicy';
import Consents from '../pages/Consents';
import Acknowledgments from '../pages/Acknowledgments';
import Finish from '../pages/Finish';
import Thanks from '../pages/Thanks';
import { SpecialistType } from '../model';
import CreditCardDetails from '../pages/CreditCardDetails';

const RouterConfig: FC = () => (
  <Routes>
    <Route path={ROOT_ROUTE} element={<BookingHome />}>
      <Route path={DOCTORS_ROUTE} element={<Specialists type={SpecialistType.SPECIALIST} />} />
      <Route path={NURSES_ROUTE} element={<Specialists type={SpecialistType.NURSE} />} />
      <Route path={CLIENT_DETAILS} element={<ClientDetails />} />
      <Route path={CLIENT_MEDICARE_CARD_DETAILS} element={<MedicareDetails />} />
      <Route path={CLIENT_CREDIT_CARD_DETAILS} element={<CreditCardDetails />} />
      <Route path={HEALTH_SUMMARY} element={<HealthSummary />} />
      <Route path={HEALTH_SUMMARY_CONTINUE} element={<HealthSummaryContinue />} />
      <Route path={FEES_AND_CANCELLATION_POLICY} element={<FeesAndCancellationPolicy />} />
      <Route path={CONSENTS} element={<Consents />} />
      <Route path={ACKNOWLEDGMENTS} element={<Acknowledgments />} />
      <Route path={FINISH} element={<Finish />} />
      <Route path={THANKS} element={<Thanks />} />
    </Route>
    <Route path="*" element={<BookingHome><NotFound /></BookingHome>} />
  </Routes>
);

export default RouterConfig;
