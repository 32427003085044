import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Avatar, Button, Modal, Typography,
} from 'antd';
import React, { FC, useCallback, useState } from 'react';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;

type BookingSummary = {
  specialist: {
    title: string;
    avatarURL: string;
    displayName: string;
    specialityInfo: string;
  }
  appointment: {
    startDateTime: string;
  }
}

type BookingConfirmModalProps = {
  bookingSummary: BookingSummary,
  visible: boolean;
  onOk: () => any;
  onCancel: () => any;
}

type BookingSummaryPanelProps = {
  bookingSummary: BookingSummary,
}

const BookingSummaryPanel: FC<BookingSummaryPanelProps> = ({ bookingSummary }) => {
  const {
    specialist: {
      avatarURL, displayName, specialityInfo, title,
    },
    appointment: { startDateTime },
  } = bookingSummary;
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <Avatar src={avatarURL} size={100} style={{ marginBottom: '30px' }} />
      <Typography.Title level={3}>{`${title} ${displayName}`.trim()}</Typography.Title>
      <p><Typography.Text>{specialityInfo}</Typography.Text></p>
      <p><Typography.Text strong>{startDateTime}</Typography.Text></p>
    </div>
  );
};

export const BookingConfirmModal: FC<BookingConfirmModalProps> = ({
  bookingSummary, visible, onOk, onCancel,
}) => {
  const [isRobot, setIsRobot] = useState(true);

  const handleOk = useCallback(() => {
    if (!isRobot) {
      onOk();
    }
  }, [onOk, isRobot]);

  const handleRobotCheckboxChange = useCallback((value) => setIsRobot(!value), [setIsRobot]);
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" disabled={isRobot} onClick={handleOk}>
          Confirm
        </Button>,
      ]}
    >
      <Typography.Title level={3}>
        Confirmation
      </Typography.Title>
      <Typography.Text>
        Please confirm the following booking information.
      </Typography.Text>
      <BookingSummaryPanel bookingSummary={bookingSummary} />
      <ReCAPTCHA
        // @ts-ignore
        style={{ width: '300px', margin: '30px auto' }}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
        onChange={handleRobotCheckboxChange}
      />
    </StyledModal>
  );
};
