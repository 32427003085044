import { Upload, Row, Col } from 'antd';
import {
  UploadOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import React, { FC, ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  UploadAlert,
  UploadButton,
} from './styles';
import {
  ATTENDED,
  BOOKED, DEFAULT,
  ERROR_ALERT,
  GREY,
  WHITE_BG,
} from '../../styles/color';
import { AttachmentWithStatus, UPLOAD_STATUS } from '../../model/Attachment';
import { QUERIES } from '../../styles/breakpoint';

export type UploadAlertUIProp = {
  icon: ReactElement,
  message: string,
  description: string,
  backgroundColor: string,
  borderColor: string,
};

export type UploadAlertProp = UploadAlertUIProp & {showIcon: boolean};

// eslint-disable-next-line no-unused-vars
const UploadForm: FC<{ uploadInfo?: AttachmentWithStatus, setUploadInfo: (data: AttachmentWithStatus | {}) => void }> = ({
  uploadInfo,
  setUploadInfo,
}) => {
  const isMobile = useMediaQuery({ query: QUERIES.mobile });
  const handleUploadChange = (data: any) => {
    const { file } = data;
    const { status, response = {} } = file;
    setUploadInfo({
      ...response,
      status,
    });
  };

  const handleDelete = () => {
    setUploadInfo({});
  };

  const getUploadAlertProp = (): UploadAlertUIProp => {
    const successProp = {
      icon: (
        <CheckCircleOutlined style={{ color: 'rgba(82, 196, 26, 0.85)' }} />
      ),
      message: 'File uploaded successfully',
      description: uploadInfo?.fileName ?? '',
      backgroundColor: BOOKED.BG,
      borderColor: BOOKED.BORDER,
      action: <DeleteOutlined onClick={() => handleDelete()} />,
    };

    const errorProp = {
      icon: <WarningOutlined style={{ color: 'rgba(255, 77, 79, 0.85)' }} />,
      message: 'Failed to upload files.',
      description: '.pdf .jpeg .png .html .doc .docx',
      backgroundColor: ERROR_ALERT.BACKGROUND,
      borderColor: ERROR_ALERT.BORDER,
    };

    const defaultProp = {
      icon: <WarningOutlined style={{ color: 'rgba(255, 77, 79, 0.85)' }} />,
      message: 'Upload file...',
      description: '.pdf .jpeg .png .html .doc .docx',
      backgroundColor: DEFAULT.BG,
      borderColor: DEFAULT.BORDER,
      color: DEFAULT.FONT,
    };

    const propMapping = {
      [UPLOAD_STATUS.SUCCESS]: successProp,
      [UPLOAD_STATUS.DONE]: successProp,
      [UPLOAD_STATUS.ERROR]: errorProp,
      [UPLOAD_STATUS.REMOVED]: errorProp,
      [UPLOAD_STATUS.UPLOADING]: {
        icon: <LoadingOutlined style={{ color: ATTENDED.BG }} />,
        message: 'Patient Referral',
        description: 'file uploading...',
        backgroundColor: WHITE_BG,
        borderColor: GREY,
      },
      [UPLOAD_STATUS.DEFAULT]: defaultProp,
    };

    return propMapping[uploadInfo?.status ?? UPLOAD_STATUS.DEFAULT];
  };

  return (
    <Row justify="space-around">
      <Col span={isMobile ? 16 : 20}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <UploadAlert {...getUploadAlertProp()} showIcon />
      </Col>
      <Col span={isMobile ? 7 : 3}>
        <Upload
          accept="image/png, image/jpeg, application/pdf, .doc, .docx"
          action={`${process.env.REACT_APP_API_BASE_URL}/files`}
          headers={{
            'X-API-KEY': process.env.REACT_APP_API_KEY as string,
            'Site-Id': process.env.REACT_APP_CURRENT_SITE_ID || '',
          }}
          showUploadList={false}
          onChange={handleUploadChange}
        >
          <UploadButton
            shape="round"
            icon={<UploadOutlined />}
            style={{ height: '40px' }}
            disabled={[
              UPLOAD_STATUS.UPLOADING,
              UPLOAD_STATUS.SUCCESS,
              UPLOAD_STATUS.DONE,
            ].includes(uploadInfo?.status ?? UPLOAD_STATUS.DEFAULT)}
          >
            Upload
          </UploadButton>
        </Upload>
      </Col>
    </Row>
  );
};

export default UploadForm;
