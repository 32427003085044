import {
  Radio, Form,
} from 'antd';
import React, { useCallback, useMemo } from 'react';

import Text from 'antd/lib/typography/Text';
import { FormContainer } from '../../../components/Container';
import FooterNavigator from '../../../components/FooterNavigator';
import { CONSENTS, FINISH } from '../../../constants/routes';
import { CONSENTS_MAPPING } from '../../../constants/consents';
import { addConsentsData } from '../../../redux/slices/booking';
import { useAppDispatch } from '../../../hooks/redux-hooks';

const AcknowledgmentsForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const submit = useCallback(async () => {
    try {
      const { usingCannabis, prescribedCannabis, patientUnder25 } = await form.validateFields();

      if (usingCannabis && prescribedCannabis && patientUnder25) {
        dispatch(addConsentsData({
          usingCannabis: { ...CONSENTS_MAPPING.usingCannabis, agreed: true },
          prescribedCannabis: { ...CONSENTS_MAPPING.prescribedCannabis, agreed: true },
          patientUnder25: { ...CONSENTS_MAPPING.patientUnder25, agreed: true },
        }));

        return true;
      }

      return false;
    } catch (errorInfo) {
      return false;
    }
  }, [form, dispatch]);

  const formItemRules = useMemo(() => ([
    {
      required: true,
      message: 'Please check this question.',
    },
    {
      pattern: /^yes$/,
      message: 'Please tick yes.',
    },
  ]), []);

  return (
    <>
      <FormContainer layout="vertical" form={form}>
        {['usingCannabis', 'prescribedCannabis', 'patientUnder25'].map((item) => (
          <Form.Item
            key={item}
            name={item}
            label={(
              <Text style={{ fontSize: '18px' }}>
                {(CONSENTS_MAPPING as any)[item].description}
              </Text>
            )}
            required
            rules={formItemRules}
          >
            <Radio.Group>
              <Radio style={{ fontSize: '18px' }} value="yes">YES</Radio>
              <Radio style={{ fontSize: '18px' }} value="no">NO</Radio>
            </Radio.Group>
          </Form.Item>
        ))}

        <FooterNavigator submit={submit} error="" next={FINISH} back={CONSENTS} containerStyle={{ marginTop: '50px' }} />
      </FormContainer>
    </>
  );
};

export default AcknowledgmentsForm;
