import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import styled from 'styled-components';
import Specialist from '../../model/Specialist';
import { generateSpecialities } from '../../utils/specialist';
import { capitalizeFirstLetter } from '../../utils/common';
import { DEVICES } from '../../styles/breakpoint';

const TextSectionContainer = styled(Space)`
  max-height: 200px;
  position: relative;
  overflow: scroll;
  text-align: left;
  
  ${DEVICES.desktop} {
    max-height: 300px;
  }
`;

// eslint-disable-next-line no-unused-vars
const SpecialistProfile: FC<{specialist: Specialist}> = ({ specialist }) => {
  const {
    firstName,
    specialities,
    profile,
    lastName,
    title,
  } = specialist;

  return (
    <TextSectionContainer direction="vertical">
      <Typography.Title level={5}>
        {`${title ? `${capitalizeFirstLetter(title)} ` : ''}${firstName} ${lastName}`}
      </Typography.Title>
      <Typography.Text style={{ fontWeight: 300, fontSize: '16px' }}>
        {generateSpecialities(specialities)}
      </Typography.Text>
      <Typography.Text
        style={{
          display: 'block',
          fontWeight: 600,
          fontSize: '14px',
          marginTop: '12px',
        }}
      >
        About
      </Typography.Text>
      {profile && (
      <Typography.Text
        style={{
          fontWeight: 400,
          fontSize: '14px',
          marginTop: '12px',
          whiteSpace: 'pre-line',
        }}
      >
        {`${profile?.join('\n')}`}
      </Typography.Text>
      )}
    </TextSectionContainer>
  );
};

export default SpecialistProfile;
