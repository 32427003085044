import React, { FC } from 'react';
import { Typography } from 'antd';
import { ContainerWithForm } from '../../components/Container';
import EwayPaymentForm from './EwayPaymentForm';

const CreditCardDetails: FC = () => (
  <ContainerWithForm>
    <Typography.Title
      level={2}
    >
      Credit Card
    </Typography.Title>
    <Typography.Paragraph strong style={{ marginBottom: '50px' }}>
      Please enter your credit card details. Payment will be processed after your appointment.
    </Typography.Paragraph>

    <EwayPaymentForm />
  </ContainerWithForm>
);

export default CreditCardDetails;
