export const CONSENTS_MAPPING = {
  isAgreedTheFees: {
    name: 'Fees and Cancellation Policy',
    description: 'I have had a chance to review the Heyday Clinic fees and agree to pay the full fee owing on the day of my consultation.',
  },
  isAgreedTheCancelPolicy: {
    name: 'Fees and Cancellation Policy',
    description: 'Missed consultations, or consultations cancelled without valid medical reason within 48 hours of the consultation, will incur a fee of $150.',
  },
  isAgreedThePrivacyPolicy: {
    name: 'Consents',
    description: 'I have had a chance to read the Heyday Privacy Policy on the website.  I also understand that the information submitted in this form will be accessed by',
  },
  isAgreedPaymentConsent: {
    name: 'Consents',
    description: 'I have read and accept the Heyday Clinic fees advertised on the website. I understand that Heyday will collect my card details for payment via Eway, a secure payment portal. I agree to pay the full fee owing immediately following my consult. I consent to Heyday using my collected card details for payment of any follow up consults I book and attend.',
  },
  isAgreedTheHeydayConsent: {
    name: 'Consents',
    description: 'I have read the Heyday consent to treatment form below, and consent to treatment with medical cannabis if my doctor prescribes medical cannabis for me. I understand the contents of this form will be covered in my consultation and I can ask my doctor any questions during my consult.',
  },
  usingCannabis: {
    name: 'Acknowledgments',
    description: 'If you are using cannabis already to self medicate for your chronic condition, we strongly recommend that you cease all black market products while under our care as these can contain harmful substances and unknown quantities of cannabis. Using only prescribed medicines allows us to better tailor and optimise your treatment protocol, mitigate side effects and potential tolerance building. Do you understand?',
  },
  prescribedCannabis: {
    name: 'Acknowledgments',
    description: 'If you are prescribed cannabis flower for inhalation this will be prescribed to use with a vaporiser as there are many benefits to your health and budget by using one of these devices. Do you understand?',
  },
  patientUnder25: {
    name: 'Acknowledgments',
    description: 'Patients under the age of 25 aren\'t regularly prescribed THC predominant medications as first line treatments due to the increased risk of addiction and potential negative impacts on the developing brain. If THC is to be considered it will only be done under exceptional circumstances after an adequate risk/benefit analysis has been considered by your treating doctor. Do you understand?',
  },
};
