import {
  Alert, Button, Form, Radio, Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ContainerWithForm, FormContainer } from '../../components/Container';
import { HealthSummaryQuestionMapping, RequestStatus } from '../../model';
import { addBookingQuestionAndAnswersData, selectBookingData } from '../../redux/slices/booking';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { bookingAppointment } from '../../services/booking';
import { BookingConfirmModal } from './BookingConfirmModal';
import { useGetSpecialistsQuery } from '../../services/specialists';
import { generateSpecialities } from '../../utils/specialist';
import { useBookingFLowType } from '../../hooks/useBookingFLowType';

const Finish = () => {
  const bookingData = useSelector(selectBookingData);
  const appointment = bookingData?.appointment;
  const isNurseFlow = useBookingFLowType() === 'nurse';

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [bookingStatus, setBookingStatus] = useState(RequestStatus.DEFAULT);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { data: specialists } = useGetSpecialistsQuery();
  const specialist = specialists?.find((_specialist) => _specialist.id === appointment?.specialistId);

  const handleSubmit = useCallback(async (): Promise<RequestStatus> => {
    setBookingStatus(RequestStatus.LOADING);
    let values = {};
    const bookingDataBody = { ...bookingData };
    try {
      values = await form.validateFields();
      await dispatch(addBookingQuestionAndAnswersData(values));
    } catch {
      setBookingStatus(RequestStatus.DEFAULT);
      return RequestStatus.DEFAULT;
    }

    bookingDataBody.questionAndAnswers = { ...bookingData.questionAndAnswers, ...values };
    const { status } = await bookingAppointment(bookingDataBody);
    setBookingStatus(status);
    return status;
  }, [dispatch, form, bookingData]);

  return (
    <ContainerWithForm>
      <BookingConfirmModal
        bookingSummary={{
          specialist: {
            avatarURL: specialist?.avatarLink || '',
            title: specialist?.title || '',
            displayName: specialist ? `${specialist.firstName} ${specialist.lastName}` : '',
            specialityInfo: generateSpecialities(specialist?.specialities),
          },
          appointment: {
            startDateTime: `${
              moment(appointment?.date, 'YYYY-MM-DD').format('dddd, DD MMMM')
            } at ${
              moment(appointment?.startTime, 'hh:mm').format('hh:mm A')
            }`,
          },
        }}
        visible={isConfirmModalOpen}
        onOk={async () => {
          setIsConfirmModalOpen(false);
          const status = await handleSubmit();
          if (status === RequestStatus.SUCCESS) {
            window.location.href = 'https://www.heydaymedical.com.au/booking-confirmation';
          }
        }}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      <Typography.Title level={2} style={{ marginBottom: '40px' }}>
        Submit
      </Typography.Title>

      <FormContainer layout="vertical" form={form} style={{ marginBottom: '40px' }}>
        <Form.Item
          label={HealthSummaryQuestionMapping.howHear}
          name="howHear"
        >
          <TextArea />
        </Form.Item>

        {!isNurseFlow
        && (
        <Form.Item
          label={HealthSummaryQuestionMapping.consultWay}
          required
          name="consultWay"
          rules={[
            {
              required: true,
              message: 'Please tell us how would you like to do your telehealth consult?',
            },
          ]}
        >
          <Radio.Group>
            <Radio value="video">Video (our preference)</Radio>
            <Radio value="phoneCall">Phone call</Radio>
          </Radio.Group>
        </Form.Item>
        )}
      </FormContainer>

      <Typography.Title level={4} style={{ textAlign: 'left' }}>
        Thank you for taking the time to give us this information.  We look forward to helping you really soon.
        <br />
        <br />
        If you need to reach us ahead of your consultation or if you have any questions, please email
        <a href="mailto:info@heydaymedical.com"> info@heydaymedical.com </a>
        or
        <a href="tel:0731862113"> phone (07) 3186 2113.</a>
      </Typography.Title>

      <Button
        disabled={bookingStatus === RequestStatus.SUCCESS}
        shape="round"
        type="primary"
        style={{ marginTop: '50px' }}
        onClick={() => setIsConfirmModalOpen(true)}
        loading={bookingStatus === RequestStatus.LOADING}
      >
        SUBMIT
      </Button>

      {
        [RequestStatus.FAILURE, RequestStatus.SUCCESS].includes(bookingStatus) && (
        <Alert
          style={{ marginTop: '20px' }}
          message={`Booking ${bookingStatus === RequestStatus.SUCCESS ? 'success' : 'failure'}`}
          type={bookingStatus === RequestStatus.SUCCESS ? 'success' : 'error'}
        />
        )
      }
    </ContainerWithForm>
  );
};

export default Finish;
