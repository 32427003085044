import {
  Col, DatePicker, Form, Input, Row, Switch, Typography,
} from 'antd';
import React, { FC, useCallback, useEffect } from 'react';
import { isNil } from 'lodash';

import { useSelector } from 'react-redux';

import moment from 'moment';
import { DATE_WITH_DASH, TABLE_TIME } from '../../../constants/time-formats';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import {
  selectBookingClientData,
  selectBookingQuestionAndAnswersData,
  updateBookingClientData,
} from '../../../redux/slices/booking';
import FooterNavigator from '../../../components/FooterNavigator';
import { CLIENT_MEDICARE_CARD_DETAILS, DOCTORS_ROUTE } from '../../../constants/routes';
import { FormContainer } from '../../../components/Container';
import AddressFormSection from '../../../components/AddressFormSection';
import { ClientData } from '../../../model';
import { mobileRegxPattern, phoneNumberRegxPattern, trimPhoneNumber } from '../../../utils/phone-number';
import { removeEmptyStrFields } from '../../../utils/object-check';
import { useBookingFLowType } from '../../../hooks/useBookingFLowType';

const ClientDetailsForm: FC = () => {
  const [form] = Form.useForm();
  const clientData = useSelector(selectBookingClientData);
  const isNurseFlow = useBookingFLowType() === 'nurse';

  const { heydayDiscount: heydayDiscountFromStore } = useSelector(selectBookingQuestionAndAnswersData);
  const dispatch = useAppDispatch();
  const submit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const {
        nextOfKin, addressPostal, addressResidential, ...otherValues
      } = values;
      dispatch(updateBookingClientData({
        ...removeEmptyStrFields(otherValues),
        addressPostal: removeEmptyStrFields(addressPostal),
        addressResidential: removeEmptyStrFields(addressResidential),
        nextOfKin: removeEmptyStrFields(nextOfKin),
        dateOfBirth: values.dateOfBirth.format(DATE_WITH_DASH),
      }));

      return true;
    } catch (errorInfo) {
      return false;
    }
  }, [form, dispatch]);

  useEffect(() => {
    const { dateOfBirth, ...otherClientData } = clientData as ClientData;
    if (!isNil(clientData)) {
      form.setFieldsValue({ ...otherClientData, heydayDiscount: heydayDiscountFromStore });
      if (dateOfBirth) {
        form.setFieldsValue({
          dateOfBirth: moment(dateOfBirth, DATE_WITH_DASH),
        });
      }
    }
  }, [clientData, form, heydayDiscountFromStore]);

  const handleUseResidentialAddress = (value: boolean) => {
    if (value) {
      const addressResidential = form.getFieldValue('addressResidential');
      form.setFieldsValue({ addressPostal: { ...addressResidential } });
    } else {
      form.setFieldsValue({ addressPostal: undefined });
    }
  };

  return (
    <>
      <FormContainer layout="vertical" form={form} name="clientDetails" scrollToFirstError>
        {isNurseFlow && (
        <Typography.Paragraph strong>
          To help our nurses prepare for your free call, we just need to collect some of your basic information.
        </Typography.Paragraph>
        )}
        <Row justify="space-between">
          <Col span={7}>
            <Form.Item
              label="First Name"
              required
              name="firstName"
              rules={[{ required: true, message: 'Please input your first name.' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              name="lastName"
              label="Last Name"
              required
              rules={[{ required: true, message: 'Please input your last name.' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="preferredName"
              label="Preferred Name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              name="dateOfBirth"
              label="Date Of Birth"
              required
              rules={[
                {
                  type: 'object' as const,
                  required: true,
                  message: 'Please select time.',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="DD/MM/YYYY"
                format={TABLE_TIME}
              />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              name="occupation"
              label="Occupation"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              name="mobile"
              label="Mobile Phone"
              required
              rules={[
                { required: true, message: 'Please input your phone number.' },
                {
                  transform: trimPhoneNumber,
                  pattern: mobileRegxPattern,
                  message: 'Please input a valid mobile number.',
                },
              ]}
            >
              <Input placeholder="04** *** ***" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="phoneNumber"
              label="Home Phone"
              rules={[
                {
                  transform: trimPhoneNumber,
                  pattern: phoneNumberRegxPattern,
                  message: 'Please input a valid phone number.',
                },
              ]}
            >
              <Input placeholder="03** *** ***" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="email"
          label="Email"
          required
          rules={[
            { required: true, message: 'Please input your email.' },
            { type: 'email', message: 'Please input a valid email address.' },
          ]}
        >
          <Input />
        </Form.Item>

        <AddressFormSection title="Residential Address" addressKey="addressResidential" isAllFieldsRequired />
        <Form.Item label="Use Residential Address As Postal Address">
          <Switch onChange={handleUseResidentialAddress} />
        </Form.Item>
        <AddressFormSection title="Postal Address" addressKey="addressPostal" />

        <Typography.Title level={5}>
          Next of Kin
        </Typography.Title>

        <Form.Item
          label="Name"
          name={['nextOfKin', 'name']}
        >
          <Input />
        </Form.Item>

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              name={['nextOfKin', 'mobile']}
              label="Mobile"
              rules={[
                {
                  transform: trimPhoneNumber,
                  pattern: mobileRegxPattern,
                  message: 'Please input a valid phone number.',
                },
              ]}
            >
              <Input placeholder="04** *** ***" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Relationship to you"
              name={['nextOfKin', 'relationshipToYou']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <FooterNavigator error="" submit={submit} next={CLIENT_MEDICARE_CARD_DETAILS} back={DOCTORS_ROUTE} containerStyle={{ marginTop: '40px' }} />
      </FormContainer>
    </>
  );
};

export default ClientDetailsForm;
