import { Layout, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_BG, MAIN_COLOR } from '../../styles/color';
import { selectBookingAppointmentData } from '../../redux/slices/booking';
import { DOCTORS_ROUTE, STANDALONE_PATHS } from '../../constants/routes';
import { AppointmentData } from '../../model';
import useScrollToTop from '../../hooks/useScrollToTop';

const { Header, Content, Footer } = Layout;

const BookingHome: FC = () => {
  const { startTime, specialistId } = useSelector(selectBookingAppointmentData) as AppointmentData;
  const { pathname } = useLocation();

  useScrollToTop();

  if ((isEmpty(startTime) || isEmpty(specialistId)) && !STANDALONE_PATHS.includes(pathname)) {
    return <Navigate to={DOCTORS_ROUTE} />;
  }

  return (
    <Layout style={{
      position: 'absolute', minHeight: '100vh', width: '100%', backgroundColor: DEFAULT_BG,
    }}
    >
      <Header style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        backgroundColor: MAIN_COLOR,
      }}
      >
        <Space size={10}>
          <img
            src="/heyday-logo.svg"
            alt="Heyday Logo"
            width={150}
          />
        </Space>
      </Header>
      <Content style={{ marginTop: 64, marginBottom: 62 }}>
        <Outlet />
      </Content>
      <Footer style={{
        textAlign: 'center', position: 'absolute', bottom: 0, width: '100%',
      }}
      >
        <Space size={10}>
          <Typography.Text>Powered By</Typography.Text>
          <img
            src="/telecare-logo.png"
            alt="Telecare logo"
            width={70}
            height={20}
          />
        </Space>
      </Footer>
    </Layout>
  );
};

export default BookingHome;
