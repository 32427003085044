import { Typography } from 'antd';
import React, { FC } from 'react';
import ClientDetailsForm from './ClientDetailsForm';
import { ContainerWithForm } from '../../components/Container';

const ClientDetails: FC = () => (
  <ContainerWithForm>
    <Typography.Title
      level={2}
      style={{ marginBottom: '50px' }}
    >
      Your Details
    </Typography.Title>

    <ClientDetailsForm />
  </ContainerWithForm>
);

export default ClientDetails;
