import { Specialists, SpecialistType } from '../model';
import Specialist from '../model/Specialist';

export const generateSpecialities = (specialities: string[] = []) => {
  if (specialities.length < 2) {
    return specialities[0];
  }

  const headSpecialities = specialities.slice(0, -1);
  return `${headSpecialities.join(',')} and ${specialities.slice(-1)}`;
};

const specialistSorter = (specialist1: Specialist, specialist2: Specialist) => {
  // Temp solution to sort the specialists, should be able to config the order from specialist profile
  const SPECIALISTS_ORDER_ARRAY = ['Melissa', 'Tendai', 'Sanjeevan', 'Priya', 'Jim'];
  if (
    SPECIALISTS_ORDER_ARRAY.includes(specialist1.firstName)
    && SPECIALISTS_ORDER_ARRAY.includes(specialist2.firstName)
  ) {
    return (
      SPECIALISTS_ORDER_ARRAY.indexOf(specialist1.firstName) - SPECIALISTS_ORDER_ARRAY.indexOf(specialist2.firstName)
    );
  }
  if (SPECIALISTS_ORDER_ARRAY.includes(specialist2.firstName)) {
    return 1;
  }
  if (SPECIALISTS_ORDER_ARRAY.includes(specialist1.firstName)) {
    return -1;
  }
  return specialist1.firstName < specialist2.firstName ? -1 : 1;
};

export const filterNurserSpecialists = (specialists: Specialists = [], type: string): Specialists => {
  const nurseIds = process.env.REACT_APP_HEYDAY_NURSE_IDS?.split(',') || [];
  return specialists
    .filter((specialist) => (type === SpecialistType.NURSE ? nurseIds.includes(specialist.id) : !nurseIds.includes(specialist.id)))
    .sort(specialistSorter);
};

export const checkIfSelectedSpecialistIsNurse = (specialistId?: string) => {
  if (!specialistId) {
    return false;
  }
  const nurseIds = process.env.REACT_APP_HEYDAY_NURSE_IDS?.split(',') || [];
  return nurseIds.includes(specialistId);
};
