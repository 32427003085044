import { Form } from 'antd';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import { isNil } from 'lodash';

import TextArea from 'antd/es/input/TextArea';
import { FormContainer } from '../../../components/Container';
import FooterNavigator from '../../../components/FooterNavigator';
import { FEES_AND_CANCELLATION_POLICY, HEALTH_SUMMARY } from '../../../constants/routes';
import {
  addBookingQuestionAndAnswersData,
  selectBookingQuestionAndAnswersData,
} from '../../../redux/slices/booking';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { HealthSummaryQuestionMapping } from '../../../model';

const HealthSummaryContinueForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const questionAndAnswersData = useSelector(selectBookingQuestionAndAnswersData);

  useEffect(() => {
    if (!isNil(questionAndAnswersData)) {
      form.setFieldsValue(questionAndAnswersData);
    }
  }, [questionAndAnswersData, form]);

  const submit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      dispatch(addBookingQuestionAndAnswersData(values));
      return true;
    } catch (errorInfo) {
      return false;
    }
  }, [form, dispatch]);

  return (
    <>
      <FormContainer layout="vertical" form={form}>
        <Form.Item
          label={HealthSummaryQuestionMapping.contactMainReason}
          required
          name="contactMainReason"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label={HealthSummaryQuestionMapping.otherSymptoms}
          name="otherSymptoms"
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label={HealthSummaryQuestionMapping.otherHealthIssue}
          name="otherHealthIssue"
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          required
          label={HealthSummaryQuestionMapping.medications}
          name="medications"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          required
          label={HealthSummaryQuestionMapping.cannabis}
          name="cannabis"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          required
          label={HealthSummaryQuestionMapping.tobaccoOrAlcohol}
          name="tobaccoOrAlcohol"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          required
          extra="Cannabis is not recommended for individuals with a personal history of schizophrenia or psychosis or serious cardiovascular issues"
          label={HealthSummaryQuestionMapping.familyHistory}
          name="familyHistory"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          required
          label={HealthSummaryQuestionMapping.allergiesAndSeverity}
          name="allergiesAndSeverity"
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label={HealthSummaryQuestionMapping.goalsOfTreatment}
          name="goalsOfTreatment"
        >
          <TextArea />
        </Form.Item>

        <FooterNavigator submit={submit} error="" next={FEES_AND_CANCELLATION_POLICY} back={HEALTH_SUMMARY} containerStyle={{ marginTop: '50px' }} />
      </FormContainer>
    </>
  );
};

export default HealthSummaryContinueForm;
