import React, { FC, useEffect, useMemo } from 'react';
import { Modal, Spin } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SpecialistList from '../../components/SpecialistList';
import { useGetSpecialistsQuery } from '../../services/specialists';
import { filterNurserSpecialists } from '../../utils/specialist';
import { SpecialistType } from '../../model';
import { DEVICES } from '../../styles/breakpoint';
import { NURSES_ROUTE } from '../../constants/routes';

type Props = {
  type: SpecialistType
}

const SpecialistsContainer = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 98%;
  
  ${DEVICES.desktop} {
    width: 80%;
    margin: 50px auto 100px;
  }
`;

const { confirm } = Modal;

const Specialists: FC<Props> = ({ type }) => {
  const { data: specialists, isLoading } = useGetSpecialistsQuery();
  const navigate = useNavigate();

  // TODO: This part of the logic should been moved into backend
  const filteredSpecialists = useMemo(() => filterNurserSpecialists(specialists, type), [specialists, type]);

  useEffect(() => {
    if (type === SpecialistType.SPECIALIST) {
      confirm({
        title: <strong>So we can provide you with the best care and match you to the appropriate doctor, please answer the following: I am over the age of 25</strong>,
        content: <i>*If you are under 25, we require you to do a free nurse call first. You will be redirected to the nurse booking page</i>,
        icon: null,
        cancelText: 'No',
        okText: <strong>Yes</strong>,
        onCancel() {
          navigate(NURSES_ROUTE);
        },
      });
    }
  }, [navigate, type]);

  return (
    <SpecialistsContainer>
      <Spin tip="Loading specialists..." spinning={isLoading}>
        {specialists && <SpecialistList specialists={filteredSpecialists} />}
      </Spin>
    </SpecialistsContainer>
  );
};

export default Specialists;
