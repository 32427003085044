import {
  Alert, Button, Form, Input, Modal, Radio,
} from 'antd';
import { useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { isEmpty } from 'lodash';

import { useNavigate } from 'react-router-dom';
import { FormContainer } from '../../../components/Container';
import FooterNavigator from '../../../components/FooterNavigator';
import {
  CLIENT_CREDIT_CARD_DETAILS,
  CLIENT_DETAILS,
  FINISH,
} from '../../../constants/routes';
import {
  addBookingQuestionAndAnswersData,
  selectBookingData,
  selectBookingMedicareCardData,
  updateBookingClientData,
  updateBookingMedicareCardData,
} from '../../../redux/slices/booking';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { checkIfSelectedSpecialistIsNurse } from '../../../utils/specialist';
import { HealthSummaryQuestionMapping } from '../../../model';

const MedicareForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const medicareCardData = useSelector(selectBookingMedicareCardData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [useNurseFlow, setUseNurseFlow] = useState(false);
  const [hasError, setHasError] = useState(false);

  const bookingData = useSelector(selectBookingData);
  const nextUrl = useMemo(() => {
    const specialistId = bookingData?.appointment?.specialistId;
    const isSelectedSpecialistNurse = checkIfSelectedSpecialistIsNurse(specialistId);
    setUseNurseFlow(isSelectedSpecialistNurse);
    return isSelectedSpecialistNurse ? FINISH : CLIENT_CREDIT_CARD_DETAILS;
  }, [bookingData?.appointment?.specialistId]);

  const submit = useCallback(async () => {
    setIsModalOpen(false);
    try {
      const values = await form.validateFields();
      const {
        heydayDiscount,
        hasOtherCards,
        fromAnExistingCannabisClinic,
        ...otherValues
      } = values;
      dispatch(updateBookingMedicareCardData(otherValues));

      if (!isEmpty(heydayDiscount)) {
        dispatch(
          addBookingQuestionAndAnswersData({
            heydayDiscount,
          }),
        );
      }

      const notes1 = [
        'hasOtherCards',
        'fromAnExistingCannabisClinic',
        'heydayDiscount',
      ].reduce((res, key: string) => {
        const value = values[key];
        return value
        // @ts-ignore
          ? res.concat(`${HealthSummaryQuestionMapping[key]}:${value};`)
          : res;
      }, '');

      if (!isEmpty(notes1)) {
        dispatch(updateBookingClientData({ notes1 }));
      }

      navigate(nextUrl);
      return true;
    } catch (errorInfo) {
      setHasError(true);
      return false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUrl]);

  const validateFormValue = useCallback(async () => {
    const values = await form.validateFields();
    const { medicareCardNumber, hasOtherCards } = values;
    const noOtherCardFilled = hasOtherCards === 'no' || !hasOtherCards;

    if (!medicareCardNumber && noOtherCardFilled) {
      setIsModalOpen(true);
      return false;
    }

    const result = await submit();
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(medicareCardData)) {
      form.setFieldsValue(medicareCardData);
    }
  }, [medicareCardData, form]);

  return (
    <>
      <FormContainer layout="vertical" form={form}>
        <Form.Item
          label="Medicare Card Number"
          name="medicareCardNumber"
          rules={[
            {
              pattern: /^(\d{10})$/,
              message: 'Please input a valid medicare card number.',
            },
          ]}
        >
          <Input placeholder="*********" />
        </Form.Item>
        <Form.Item
          label="Medicare Card IRN"
          name="medicareCardIRN"
          rules={[
            {
              pattern: /^(\d)$/,
              message: 'Please input a valid medicare card IRN.',
            },
          ]}
        >
          <Input placeholder="*" />
        </Form.Item>
        {!useNurseFlow && (
          <>
            <Form.Item
              name="hasOtherCards"
              label="Do you have a healthcare card, DVA veteran card, aged pension card or are you on a disability support pension?"
            >
              <Radio.Group>
                <Radio value="yes">YES</Radio>
                <Radio value="no">NO</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="fromAnExistingCannabisClinic"
              label="Are you coming to us from an existing cannabis clinic or doctor and booking a Transfer Consult?"
            >
              <Radio.Group>
                <Radio value="yes">YES</Radio>
                <Radio value="no">NO</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={HealthSummaryQuestionMapping.heydayDiscount}
              name="heydayDiscount"
            >
              <Input />
            </Form.Item>
          </>
        )}
        <FooterNavigator
          error=""
          submit={validateFormValue}
          next={nextUrl}
          back={CLIENT_DETAILS}
        />
        <Modal
          title="Would you like to proceed without Medicare?"
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button onClick={submit}>Yes</Button>,
            <Button type="primary" onClick={() => setIsModalOpen(false)}>
              Go Back
            </Button>,
          ]}
        >
          <p>As part of our commitment to providing you with the best healthcare service and ensuring you will receive your electronic prescription after your consultation, we kindly request your Medicare or DVA card number. Without Medicare / DVA card number, your doctor is unable to send electronic prescription to you or your pharmacy, paper script will be issued instead, which might lead to delays of dispensing the medication.</p>
        </Modal>
        {hasError && (
          <Alert
            style={{ marginTop: '20px' }}
            message="Ops, something went wrong"
            type="error"
          />
        )}
      </FormContainer>
    </>
  );
};

export default MedicareForm;
