import moment from 'moment';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { FONT, WHITE_BG } from '../../styles/color';
import { DATE_WITH_DASH_WITH_TIME, HOUR_WITH_AM_PM } from '../../constants/time-formats';
import { BookingTime } from '../../model';

export const SelectedTimeLabel = styled.div`
  width: 100%;
  background: ${WHITE_BG};
  border: 1px solid ${FONT};
  border-radius: 16px;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  margin-top: 8px;
`;

export const SelectedTimeContainer = styled.div`
  text-align: center;
  font-size: 14px;
  margin: 22px 0 35px;
`;

const SelectedTime: FC<{ bookingTime: BookingTime }> = ({ bookingTime: { date, startTime } }) => {
  const isNoData = useMemo(() => (!date || !startTime), [date, startTime]);

  return (
    <SelectedTimeContainer>
      <Typography.Title level={4}>{isNoData ? 'No Selected' : 'Selected Time'}</Typography.Title>
      {!isNoData && (
      <SelectedTimeLabel>
        {moment(`${date}${startTime}`, DATE_WITH_DASH_WITH_TIME).format(`dddd, ${HOUR_WITH_AM_PM}`)}
      </SelectedTimeLabel>
      )}
    </SelectedTimeContainer>
  );
};
export default SelectedTime;
