import * as firebaseApp from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

class FirebaseService {
  constructor() {
    firebaseApp.initializeApp(config);
  }
}

const firebaseService = new FirebaseService();

export default firebaseService;
