import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col, Form, Input, Row, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { CLIENT_MEDICARE_CARD_DETAILS, HEALTH_SUMMARY } from '../../../constants/routes';
import FooterNavigator from '../../../components/FooterNavigator';
import {
  createTokenCustomer,
  createTokenCustomerOnEway,
  fetchTokenCustomerCreationResult,
} from '../../../services/payment';
import { addEwayPaymentData, selectBookingClientData } from '../../../redux/slices/booking';
import { RequestStatus } from '../../../model';
import { CONSENTS_MAPPING } from '../../../constants/consents';
import { useAppDispatch } from '../../../hooks/redux-hooks';

const EwayPaymentForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const patientData = useSelector(selectBookingClientData);

  const [eWayAccessCode, setEWayAccessCode] = useState('');
  const [eWayFormActionURL, setEWayFormActionURL] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { data, error } = await createTokenCustomer({
        firstName: patientData.firstName || '',
        lastName: patientData.lastName || '',
        email: patientData.email || '',
        mobile: patientData.mobile || '',
        redirectUrl: 'https://www.google.com',
      });
      if (error || !data) {
        return;
      }
      const { accessCode, formActionURL } = data;
      setEWayAccessCode(accessCode);
      setEWayFormActionURL(formActionURL);
    })();
  }, [patientData]);

  const submit = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const values = await form.validateFields();
      await createTokenCustomerOnEway(eWayFormActionURL, {
        ...values,
        EWAY_ACCESSCODE: eWayAccessCode,
        EWAY_PAYMENTTYPE: 'Credit Card',
      });
      const { status, data } = await fetchTokenCustomerCreationResult(eWayAccessCode);
      if (status === RequestStatus.SUCCESS && data.tokenCustomerID) {
        dispatch(addEwayPaymentData({
          customerId: data.tokenCustomerID,
        }));
        return true;
      }
      setErrorMessage('Setup payment method failed. Please try again later or contact Heyday at info@heydaymedical.com');
      return false;
    } catch (e) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form
        form={form}
        id="payment_form"
        layout="vertical"
        method="POST"
        style={{ textAlign: 'start' }}
      >
        <Form.Item
          required
          label="Cardholder name:"
          name="EWAY_CARDNAME"
          rules={[{
            required: true,
            transform: (value) => value?.trim(),
            message: 'Please input the card holder name.',
          }]}
        >
          <Input required placeholder="Name on card" name="EWAY_CARDNAME" />
        </Form.Item>
        <Form.Item
          required
          label="Credit Card number(no space):"
          name="EWAY_CARDNUMBER"
          rules={[
            {
              required: true,
              message: 'Please input the card number.',
            },
            {
              pattern: /^(\d{16})$/,
              message: 'Please input a valid credit card number.',
            },
          ]}
        >
          <Input required placeholder="**** **** **** ****" name="EWAY_CARDNUMBER" />
        </Form.Item>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              required
              label="Expiry Month:"
              name="EWAY_CARDEXPIRYMONTH"
              rules={[{
                required: true,
                message: 'Please input the expiry month.',
              }, {
                pattern: /^0[1-9]|1[0-2]$/,
                message: 'Please input a valid month.',
              }]}
            >
              <Input required placeholder="MM" name="EWAY_CARDEXPIRYMONTH" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              required
              label="Expiry Year:"
              name="EWAY_CARDEXPIRYYEAR"
              rules={[{
                required: true,
                message: 'Please input the expiry year.',
              }, {
                pattern: /^(19|20)\d{2}$/,
                message: 'Please input a valid year.',
              }]}
            >
              <Input required placeholder="YYYY" name="EWAY_CARDEXPIRYYEAR" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          required
          label="CVC:"
          name="EWAY_CARDCVN"
          rules={[{
            required: true,
            message: 'Please input the cvc number.',
          }, {
            pattern: /^(\d{3}|\d{4})$/,
            message: 'Please input a valid CVC.',
          }]}
        >
          <Input required placeholder="***" name="EWAY_CARDCVN" />
        </Form.Item>
        <Form.Item
          name="payment_consent"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please agree to the payment consent before continue.'))),
            },
          ]}
        >
          <Checkbox>
            {CONSENTS_MAPPING.isAgreedPaymentConsent.description}
          </Checkbox>
        </Form.Item>
      </Form>

      <div id="eWAYBlock">
        <div style={{ textAlign: 'center', margin: '30px auto' }}>
          <a href="https://www.eway.com.au/developers/nz/secure-site-seal?i=12&amp;se=3&amp;theme=0" title="eWAY Payment Gateway" target="_blank" rel="nofollow noreferrer">
            <img alt="eWAY Payment Gateway" src="https://www.eway.com.au/developers/developer/payment-code/verified-seal.php?img=12&amp;size=3&amp;theme=0" />
          </a>
        </div>
      </div>

      <div>
        {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
      </div>
      <FooterNavigator error="" submit={submit} next={HEALTH_SUMMARY} back={CLIENT_MEDICARE_CARD_DETAILS} loading={isLoading} />
    </>
  );
};

export default EwayPaymentForm;
