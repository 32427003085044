import { Space, Typography } from 'antd';
import React, { FC } from 'react';
import { ContainerWithForm } from '../../components/Container';
import HealthSummaryForm from './HealthSummaryForm';
import { NURSES_ROUTE } from '../../constants/routes';

const HealthSummary: FC = () => (
  <ContainerWithForm>
    <Space direction="vertical" size={50}>
      <Typography.Title
        level={2}
      >
        Your Health Summary
      </Typography.Title>

      <Typography.Text style={{ fontSize: '18px', textAlign: 'left', display: 'block' }}>
        This page may take 5-10 minutes to complete. Our doctors use this information to prepare for your consultation ahead of time, ensuring you can jump right in and maximise your time together. Just answer the questions to the best of your knowledge and ability. If you get stuck, one of our nurses can complete the form for you over the phone and book you in directly. Book a free nurse call
        <a href={NURSES_ROUTE} style={{ textDecoration: 'underline' }}> here</a>
        .
      </Typography.Text>

      <HealthSummaryForm />
    </Space>
  </ContainerWithForm>
);

export default HealthSummary;
