/* eslint-disable no-unused-vars */
export enum HealthSummaryQuestionMapping {
  contactMainReason='Your main reason for contact',
  otherSymptoms='Other symptoms you would like help with',
  otherHealthIssue='Do you have any other health issues we should be aware of?',
  medications='Are you currently taking any medications (including vitamins and herbal preparations)?',
  cannabis='Current and past cannabis use',
  tobaccoOrAlcohol='Current and past tobacco/alcohol use',
  familyHistory='Do you have any significant family history we should be aware of?',
  allergiesAndSeverity='Allergies and severity',
  goalsOfTreatment='Apart from feeling better and getting some relief, do you have any other goals of treatment with Heyday?',
  gpOrSpecialistName='Name and Practice Name of your usual GP and/or specialist? If you don’t have one, let us know that too',
  ifSendToGpOrSpecialist='Would you like correspondence sent to your GP or Specialist regarding your treatment?',
  consultWay='Hooray - last question!  How would you like to do your telehealth consult?',
  howHear='How did you hear about us?',
  heydayDiscount='Do you have a Heyday discount code?',
  hasOtherCards='Do you have a healthcare card, DVA veteran card, aged pension card or are you on a disability support pension?',
  fromAnExistingCannabisClinic='Are you coming to us from an existing cannabis clinic or doctor and booking a Transfer Consult?',
}
