import { Space, Typography } from 'antd';
import React, { FC } from 'react';
import { ContainerWithForm } from '../../components/Container';
import HealthSummaryContinueForm from './HealthSummaryContinueForm';

const HealthSummaryContinue: FC = () => (
  <ContainerWithForm>
    <Space direction="vertical" size={50}>
      <Typography.Title
        level={2}
      >
        Your Health Summary continued
      </Typography.Title>

      <HealthSummaryContinueForm />
    </Space>
  </ContainerWithForm>
);

export default HealthSummaryContinue;
