import { Typography } from 'antd';
import moment, { Moment } from 'moment';
import React, { CSSProperties, FC, RefObject } from 'react';
import { AvailableTimeslotsContainer, TimeTag } from './styles';
import {
  DATE_WITH_DASH,
  DATE_WITH_DASH_WITH_TIME,
  HOUR,
  HOUR_WITH_AM_PM,
  MONTH,
  WEEK_MONTH,
} from '../../constants/time-formats';
import { AvailableTimeForUI, BookingTime, Timeslot as TimeslotType } from '../../model';

type TagData = { start: Moment; end: Moment };
const UNIT = 'm';

const generateTimeMoment = (date: string, time: string): Moment => moment(`${date}-${time}`, `${DATE_WITH_DASH}-${HOUR}`);
const generateTimeTagList = (
  availableTime: AvailableTimeForUI,
  aptLength: string,
): TagData[] => {
  if (availableTime) {
    const { date, isToday } = availableTime;
    let { availableTimeslots } = availableTime;
    if (isToday) {
      availableTimeslots = availableTimeslots.reduce((result: TimeslotType[], { start, end }) => {
        const startMoment = generateTimeMoment(date, start);
        const endMoment = generateTimeMoment(date, end);
        const current = moment();
        let validStartTime = start;
        if (endMoment.isBefore(current)) {
          return result;
        }
        if (startMoment.isBefore(current)) {
          validStartTime = moment(current)
            .startOf('hour')
            .add(Math.ceil(current.minutes() / 10) * 10, 'minutes')
            .format('HH:mm');
        }
        return [...result, { start: validStartTime, end }];
      }, [] as TimeslotType[]);
    }
    return availableTimeslots.reduce((result: TagData[], { start, end }) => {
      const startMoment = generateTimeMoment(date, start);
      const endMoment = generateTimeMoment(date, end);
      const timeslots = [];
      let startTemp = startMoment;
      while (moment(startTemp).add(aptLength, UNIT) <= endMoment) {
        const endTemp: Moment = moment(startTemp).add(aptLength, UNIT);
        timeslots.push({ start: startTemp, end: endTemp });
        startTemp = endTemp;
      }
      return [...result, ...timeslots];
    }, []);
  }
  return [];
};

type Props = {
  availableTime: AvailableTimeForUI;
  aptLength: string;
  bookingTime: BookingTime;
  // eslint-disable-next-line no-unused-vars
  updateBookingTime: (data: BookingTime) => void;
  titleStyle: CSSProperties;
};

const Timeslot: FC<Props> = ({
  availableTime,
  titleStyle = {},
  aptLength,
  bookingTime,
  updateBookingTime,
}) => {
  if (!availableTime) {
    return null;
  }

  const {
    date, isToday, isTomorrow, ref, availableTimeslots,
  } = availableTime;

  if (isToday && generateTimeMoment(date, availableTimeslots[0].end).isBefore(moment().add(aptLength, UNIT))) {
    return null;
  }
  const handleSelectTimeslot = (startMoment: Moment) => updateBookingTime({
    startTime: startMoment.format(HOUR),
    month: startMoment.format(MONTH),
    date: startMoment.format(DATE_WITH_DASH),
  });

  const generateFormat = () => {
    if (isToday) {
      return '[Today,] Do MMMM';
    }

    if (isTomorrow) {
      return '[Tomorrow,] Do MMMM';
    }

    return WEEK_MONTH;
  };

  return (
    <AvailableTimeslotsContainer ref={ref as RefObject<HTMLDivElement>}>
      <Typography.Title
        level={5}
        style={{ margin: '15px 0px', textAlign: 'center', ...titleStyle }}
      >
        {moment(date, DATE_WITH_DASH).format(generateFormat())}
      </Typography.Title>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto auto',
          gridColumnGap: '6px',
        }}
      >
        {generateTimeTagList(availableTime, aptLength).map(({ start }) => (
          <TimeTag
            key={start.unix()}
            onClick={() => handleSelectTimeslot(start)}
            isSelected={
              start.format(DATE_WITH_DASH_WITH_TIME)
              === `${bookingTime.date}${bookingTime.startTime}`
            }
          >
            {moment(start).format(HOUR_WITH_AM_PM)}
          </TimeTag>
        ))}
      </div>
    </AvailableTimeslotsContainer>
  );
};

export default Timeslot;
