// TODO booking api
import { isNil } from 'lodash';
import moment from 'moment';
import {
  AppointmentData,
  BookingData,
  BookingDataState,
  ClientData,
  Consents, HealthSummaryQuestionMapping,
  HttpResponse, Map,
  QuestionAndAnswers,
  RequestStatus, RequiredAddress,
} from '../model';
import { DATE_WITH_DASH_WITH_TIME } from '../constants/time-formats';
import request from '../infra/request';
import { checkIfSelectedSpecialistIsNurse } from '../utils/specialist';

const validateBookingData = (bookingData: BookingDataState): boolean => {
  const {
    appointment, client, medicareCard, questionAndAnswers, consents = {},
  } = bookingData;

  if (isNil(appointment) || isNil(client) || isNil(medicareCard) || isNil(questionAndAnswers)) {
    return false;
  }

  const {
    specialistId, type, date, startTime, aptLength,
  } = appointment as AppointmentData;
  if (isNil(specialistId) || isNil(type) || isNil(date) || isNil(startTime) || isNil(aptLength)) {
    return false;
  }

  const isNurseFlow = checkIfSelectedSpecialistIsNurse(specialistId);

  const {
    firstName, lastName, dateOfBirth, mobile, email, addressResidential,
  } = client as ClientData;

  const {
    streetAddress, suburb, state, postcode,
  } = addressResidential as RequiredAddress;

  if (isNil(firstName) || isNil(lastName) || isNil(dateOfBirth)
    || isNil(mobile) || isNil(email) || isNil(streetAddress)
    || isNil(suburb) || isNil(state) || isNil(postcode)
  ) {
    return false;
  }

  if (isNurseFlow) {
    return true;
  }

  const {
    gpOrSpecialistName, ifSendToGpOrSpecialist, contactMainReason, medications, cannabis, tobaccoOrAlcohol, familyHistory, allergiesAndSeverity,
  } = questionAndAnswers as QuestionAndAnswers;
  if (isNil(gpOrSpecialistName) || isNil(ifSendToGpOrSpecialist) || isNil(contactMainReason) || isNil(medications) || isNil(cannabis) || isNil(tobaccoOrAlcohol) || isNil(familyHistory) || isNil(allergiesAndSeverity)) {
    return false;
  }

  const {
    isAgreedTheFees, isAgreedTheCancelPolicy, isAgreedThePrivacyPolicy, isAgreedTheHeydayConsent, usingCannabis, prescribedCannabis, patientUnder25,
  } = consents as Consents;
  return !(isNil(isAgreedTheFees) || isNil(isAgreedTheCancelPolicy) || isNil(isAgreedThePrivacyPolicy) || isNil(isAgreedTheHeydayConsent) || isNil(usingCannabis) || isNil(prescribedCannabis) || isNil(patientUnder25));
};

const transformBookingData = (bookingData: BookingData) => {
  const data: any = {};
  const {
    appointment, client, medicareCard, questionAndAnswers, consents, attachments, ewayPayment,
  } = bookingData;
  const {
    type, date, startTime, aptLength, specialistId,
  } = appointment as AppointmentData;
  const startDateMoment = moment(`${date}${startTime}`, DATE_WITH_DASH_WITH_TIME);

  data.appointment = {
    appointmentType: type,
    startDateTime: startDateMoment.format(),
    endDateTime: startDateMoment.clone().add(aptLength, 'minutes').format(),
  };
  data.specialist = { specialistId };
  data.client = {
    ...client,
    medicareCard,
    ewayPayment: ewayPayment ? {
      customerId: ewayPayment.customerId,
    } : null,
    notes1: `${client.notes1 || ''}\neWay Customer ID: ${ewayPayment?.customerId || ''}`,
  };
  data.healthSummary = {
    questionAndAnswers: Object.keys(questionAndAnswers).map((key) => ({
      question: (HealthSummaryQuestionMapping as Map)[key],
      // @ts-ignore
      answer: questionAndAnswers[key],
    })),
  };

  if (attachments) {
    data.healthSummary.attachments = Object.values(attachments).map(({ fileId, fileName }) => ({ fileId, fileName }));
  }

  data.consents = Object.values(consents || {});

  const isNurseFlow = checkIfSelectedSpecialistIsNurse(specialistId);
  data.metadata = {
    bookingFlowType: isNurseFlow ? 'nurse' : 'specialist',
  };

  return data;
};

export const bookingAppointment = async (bookingData: BookingDataState): Promise<HttpResponse<any>> => {
  const isValid = validateBookingData(bookingData);
  if (isValid) {
    const formattedBookingData = transformBookingData(bookingData as BookingData);
    try {
      const { status } = await request.post<any>('/bookings', formattedBookingData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY || '',
        },
      });

      if (status === 201) {
        return { status: RequestStatus.SUCCESS };
      }

      return { status: RequestStatus.FAILURE, error: 'Booking failed' };
    } catch (error) {
      return { status: RequestStatus.FAILURE, error: error as string };
    }
  }

  return { status: RequestStatus.FAILURE, error: 'Request validation failed' };
};
