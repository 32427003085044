export const ROOT_ROUTE = '';
export const DOCTORS_ROUTE = `${ROOT_ROUTE}/doctors`;
export const NURSES_ROUTE = `${ROOT_ROUTE}/nurses`;
export const CLIENT_DETAILS = `${ROOT_ROUTE}/client-details`;
export const CLIENT_MEDICARE_CARD_DETAILS = `${ROOT_ROUTE}/client-medicare-card-details`;
export const CLIENT_CREDIT_CARD_DETAILS = `${ROOT_ROUTE}/client-credit-card-details`;
export const HEALTH_SUMMARY = `${ROOT_ROUTE}/health-summary`;
export const HEALTH_SUMMARY_CONTINUE = `${ROOT_ROUTE}/health-summary-continue`;
export const FEES_AND_CANCELLATION_POLICY = `${ROOT_ROUTE}/fees-and-cancellation-policy`;
export const CONSENTS = `${ROOT_ROUTE}/consents`;
export const ACKNOWLEDGMENTS = `${ROOT_ROUTE}/acknowledgments`;
export const CONSULTATION_FEES = `${ROOT_ROUTE}/consultation-fees`;
export const FINISH = `${ROOT_ROUTE}/finish`;
export const THANKS = `${ROOT_ROUTE}/thanks`;

export const STANDALONE_PATHS = [
  NURSES_ROUTE, `${NURSES_ROUTE}/`,
  DOCTORS_ROUTE, `${DOCTORS_ROUTE}/`,
  THANKS,
];
