import {
  Alert, Typography, Row,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { ContainerWithForm } from '../../components/Container';
import { ACKNOWLEDGMENTS, FEES_AND_CANCELLATION_POLICY } from '../../constants/routes';
import FooterNavigator from '../../components/FooterNavigator';
import AgreeCheck from '../../components/AgreeCheck';
import { addConsentsData } from '../../redux/slices/booking';
import { CONSENTS_MAPPING } from '../../constants/consents';
import { useAppDispatch } from '../../hooks/redux-hooks';

const treatmentFormLink = 'https://uploads-ssl.webflow.com/60c956691a50ab773621833e/61667555cfba82689ba0bbd6_Heyday%20Clinic%20Patient%20Consent%20Form%20-%20for%20telecare.docx.pdf';

const Consents = () => {
  const [isAgreedThePrivacyPolicy, setIsAgreedThePrivacyPolicy] = useState(true);
  const [isAgreedTheHeydayConsent, setIsAgreedTheHeydayConsent] = useState(true);
  const dispatch = useAppDispatch();

  const submit = useCallback(() => {
    if (isAgreedThePrivacyPolicy && isAgreedTheHeydayConsent) {
      dispatch(addConsentsData({
        isAgreedThePrivacyPolicy: { ...CONSENTS_MAPPING.isAgreedThePrivacyPolicy, agreed: true },
        isAgreedTheHeydayConsent: { ...CONSENTS_MAPPING.isAgreedTheHeydayConsent, agreed: true },
      }));
      return true;
    }

    return false;
  }, [dispatch, isAgreedThePrivacyPolicy, isAgreedTheHeydayConsent]);

  return (
    <ContainerWithForm>
      <Typography.Title
        level={2}
        style={{ marginBottom: '20px', textAlign: 'center' }}
      >
        Consents
      </Typography.Title>

      <Row>
        <Typography.Text style={{ marginBottom: '10px', textAlign: 'left', fontSize: '18px' }}>
          I have had a chance to read the Heyday Privacy Policy on the website.  I also understand that the information submitted in this form will be accessed by:
          <ul>
            <li>The Heyday Clinic team of nurses and doctors to ensure I receive optimal care</li>
            <li>The practice management and reception team employed by Heyday, being necessary staff only at Telecare Australia</li>
          </ul>
        </Typography.Text>
        <AgreeCheck
          isAgreed={isAgreedThePrivacyPolicy}
          setIsAgreed={setIsAgreedThePrivacyPolicy}
        />
      </Row>

      <Row>
        <Typography.Text style={{ marginBottom: '10px', textAlign: 'left', fontSize: '18px' }}>
          I have read the Heyday consent to treatment form below, and consent to treatment with medical cannabis if my doctor prescribes medical cannabis for me. I understand the contents of this form will be covered in my consultation and I can ask my doctor any questions during my consult.
        </Typography.Text>
        <a
          href={treatmentFormLink}
          style={{ textDecoration: 'underline', fontSize: '18px' }}
          target="_blank"
          rel="noreferrer"
        >
          Consent to treatment form
        </a>
        <AgreeCheck
          isAgreed={isAgreedTheHeydayConsent}
          setIsAgreed={setIsAgreedTheHeydayConsent}
        />
      </Row>

      {(!isAgreedThePrivacyPolicy || !isAgreedTheHeydayConsent) && (
        <Alert
          message={`Please agree to the ${!isAgreedThePrivacyPolicy ? 'privacy policy' : 'heyday consent'} before continue.`}
          type="error"
        />
      )}

      <FooterNavigator submit={submit} error="" next={ACKNOWLEDGMENTS} back={FEES_AND_CANCELLATION_POLICY} containerStyle={{ marginTop: '50px' }} />
    </ContainerWithForm>
  );
};

export default Consents;
