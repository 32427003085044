import React, {
  FC, useCallback, useMemo, useState, createContext,
} from 'react';
import {
  Col, Avatar, Row, Spin,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { MAIN_COLOR } from '../../styles/color';
import Specialist from '../../model/Specialist';
import {
  selectBookingAppointmentData,
} from '../../redux/slices/booking';
import SpecialistProfile from '../SpecialistProfile';
import SelectBookingTimeModal from '../SelectBookingTimeModal';
import { useGetAvailabilitiesBySpecialistQuery } from '../../services/availabilities';
import { AppointmentData, AppointmentType, BookingTime } from '../../model';
import { MONTH } from '../../constants/time-formats';
import BookingCalendar from '../BookingCalendar';

type BookingTimeContextType = {
  bookingTime: BookingTime;
  // eslint-disable-next-line no-unused-vars
  updateBookingTime: (data: BookingTime) => void;
};

const initBookingTimeContext = {
  bookingTime: {
    month: moment().format(MONTH),
  },
  updateBookingTime: () => {},
};

export const BookingTimeContext = createContext<BookingTimeContextType>(initBookingTimeContext);

const SpecialistCard: FC<{specialist: Specialist}> = ({ specialist }) => {
  const {
    avatarLink,
    id,
  } = specialist;

  const { type } = useSelector(selectBookingAppointmentData) as AppointmentData;
  const appointmentType = useMemo(() => type ?? AppointmentType.NEW, [type]);
  const [isBookingTimeModalOpen, setIsBookingTimeModalOpen] = useState(false);
  const [bookingTime, setBookingTime] = useState(initBookingTimeContext.bookingTime);

  const { isLoading: isLoadingAvailableTimeList, data: availableTimeList } = useGetAvailabilitiesBySpecialistQuery({
    specialistId: id,
    appointmentType,
    month: bookingTime.month,
  });

  const handleOpenBookingModal = useCallback(() => {
    setIsBookingTimeModalOpen(true);
  }, [setIsBookingTimeModalOpen]);

  const renderCalendar = () => (
    <Spin spinning={isLoadingAvailableTimeList} tip="loading...">
      {availableTimeList && (
        <BookingCalendar
          handleClickCalendar={handleOpenBookingModal}
          availableTimeList={availableTimeList}
          bookingTime={bookingTime}
          updateBookingTime={setBookingTime}
        />
      )}
    </Spin>
  );

  return (
    <BookingTimeContext.Provider value={{ bookingTime, updateBookingTime: setBookingTime }}>
      <Row gutter={[6, 20]} style={{ width: '100%' }}>
        <Col md={5} lg={3} xl={4}>
          <Avatar
            src={avatarLink}
            icon={<UserOutlined />}
            size={88}
            style={{
              border: `1px solid ${MAIN_COLOR}`,
              marginRight: '6px',
            }}
          />
        </Col>

        <Col md={19} lg={8} xl={12}>
          <SpecialistProfile specialist={specialist} />
        </Col>

        <Col md={24} lg={13} xl={8}>
          {renderCalendar()}
        </Col>

        <SelectBookingTimeModal specialist={specialist} isBookingTimeModalOpen={isBookingTimeModalOpen} setIsBookingTimeModalOpen={setIsBookingTimeModalOpen} />
      </Row>
    </BookingTimeContext.Provider>
  );
};

export default SpecialistCard;
