import { Typography } from 'antd';
import React from 'react';
import { ContainerWithForm } from '../../components/Container';
import AcknowledgmentsForm from './AcknowledgementsForm';

const Acknowledgments = () => (
  <ContainerWithForm>
    <Typography.Title level={2} style={{ marginBottom: '50px' }}>Acknowledgments</Typography.Title>
    <AcknowledgmentsForm />
  </ContainerWithForm>
);

export default Acknowledgments;
