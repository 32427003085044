import { Typography } from 'antd';
import React from 'react';

import {
  Container, TextSection, Logo, MainSection,
} from './styles';
import { MAIN_COLOR } from '../../styles/color';

const { Title } = Typography;

const Thanks = () => (
  <Container>
    <header>
      <Logo alt="logo" width="70%" height="68px" src="/heyday-logo-with-blue-text.svg" />
    </header>

    <MainSection>
      <Typography.Title
        level={2}
        style={{
          marginBottom: '70px', fontWeight: 800,
        }}
      >
        Thank you for booking with

        <span style={{ color: MAIN_COLOR, paddingLeft: '10px' }}>
          Heyday
        </span>
      </Typography.Title>
      <img alt="heyday" width="100%" height="242px" src="/heyday-tree.svg" />
    </MainSection>

    <TextSection>
      <Title level={4}>
        You will receive an email and SMS confirmation shortly.
      </Title>

      <Title level={4}>
        If you are having problems please mail
        <a style={{ textDecoration: 'underline' }} href="mailto:info@heydaymedical.com"> info@heydaymedical.com </a>
        to speak with us.
      </Title>

      <Typography.Text style={{ marginTop: '50px', display: 'block', textDecoration: 'underline' }}><a href="https://www.heydaymedical.com.au/">Back to Home</a></Typography.Text>
    </TextSection>
  </Container>
);

export default Thanks;
